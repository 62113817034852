import React, { useState } from "react";
import ReviewModal from "./review-modal";
import { useSubscriptionContext } from "../contexts/SubscriptionContextProvider";
import { useNavigate } from "react-router-dom";
import { infoToast } from "../utils/utilityFunctions";

const AddReview = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { plansList, getAllPlans } = useSubscriptionContext();
  const navigate = useNavigate();

  const hasActivePlan = plansList?.plans?.some((plan) => plan.is_active === 1);
  const handleClose = () => setIsOpen(!isOpen);
  const handleSubmit = (data) => {
    console.log("Review Submitted:", data);
    setIsOpen(!isOpen); // Close modal after submitting
  };

  const handleReviewModal = () => {
    if (hasActivePlan) {
      setIsOpen(!isOpen);
    } else {
      navigate("/see-plans");
      infoToast("Buy plan first");
    }
  };
  return (
    <div className="z-[9999]">
      <div className="fixed right-0 top-1/2 -translate-y-1/2 group">
        {/* Tooltip-like Button */}
        <button
          onClick={handleReviewModal}
          className="absolute top-1/2 -right-6 transform -translate-y-1/2 rotate-90 bg-slate-300 text-black px-3 py-2 rounded-b-lg text-md font-medium tracking-wide hover:bg-customGreen hover:text-white shadow-lg"
        >
          Feedback
        </button>

        {/* Tooltip Effect */}
        <span className="absolute w-36 text-center right-12 top-1/2 -translate-y-1/2 hidden group-hover:block bg-black text-white text-xs px-2 py-1 rounded-md">
        <span className="text-yellow-500 text-lg">★</span> Leave a Review <span className="text-yellow-500 text-lg">★</span>
        </span>
      </div>

      {/* Review Form */}
      {isOpen && (
        <ReviewModal
          isOpen={isOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default AddReview;
