import React, { createContext, useContext, useState } from "react";
import { jsonHeader } from "../apis/header";
import ApiTemplate from "../apis/api_template";

const ReviewContext = createContext();

export const ReviewContextProvider = ({ children }) => {
  const [allReviews, setAllReviews] = useState(null);
  const [isReviews, setIsReviews] = useState(false);
  const [isAddReview, setIsAddReview] = useState(false);
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));

  const getAllReviews = async () => {
    setIsReviews(true);
    try {
      const response = await ApiTemplate(
        "get",
        "/api/v1/getReview",
        {},
        jsonHeader(token)
      );
      if (response.success) {
        setAllReviews(response.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsReviews(false);
    }
  };

  const addReview = async (payload) => {
    setIsAddReview(true);
    try {
      const response = await ApiTemplate(
        "post",
        "/api/v1/review",
        payload,
        jsonHeader(token)
      );
      if (response.success) {
        console.log("Success add review :", response);
        setIsAddReview(false);
        getAllReviews();
      }
    } catch (error) {
      console.log("Error to add review :", error.message);
    } finally {
      setIsAddReview(false);
    }
  };

  return (
    <ReviewContext.Provider value={{ getAllReviews, allReviews, isReviews, addReview, isAddReview }}>
      {children}
    </ReviewContext.Provider>
  );
};

export const useReviewsContext = () => {
  const reviewsContextValue = useContext(ReviewContext);
  return reviewsContextValue;
};
