import React, {createContext, useContext, useEffect, useState} from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";

const BookingContext= createContext();

export const BookingContextProvider = ({children}) => {

  const [allBookedEvents, setAllBookedEvents]=useState(null);
  const [successBookedList, setSuccessBookedList] = useState(false);
  

    const getBookingList = async (search = "") => {
      setSuccessBookedList(true);
      const token = JSON.parse(localStorage.getItem('token'));
        try {
          const headers = jsonHeader(token);
    
          const response = await ApiTemplate("get", `/api/v1/getUserBooking?search=${search}`, {}, headers);
          if (response && response["success"] === true) {
            setAllBookedEvents(response["data"]["data"]);
            setSuccessBookedList(false);
          } else {
            console.log(response["message"], response.message);
          }
        } catch (error) {
          console.error("Error during API call:", error);
          setSuccessBookedList(false);
        }
      };

      useEffect(()=>{
        getBookingList()
      },[])

      return(
        <BookingContext.Provider
        value={{
            getBookingList,
            allBookedEvents,
            successBookedList
        }}
        >
            {children}
        </BookingContext.Provider>
      )
}

export const useBookingContext = () => {
    const bookingContextValue = useContext(BookingContext);
    return bookingContextValue;
};
